<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <h2>
            {{ nome }}
          </h2>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Selecione a Disciplina :"
              label-for="disciplina"
            >
              <v-select
                v-model="disciplina"
                label="name"
                placeholder="Selecione a Disciplina"
                :options="opcoesdisciplina"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione a Atividade :"
              label-for="atividade"
            >
              <v-select
                v-model="atividade"
                label="name"
                placeholder="Selecione a Atividade"
                :options="opcoesatividade"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="SalvarCliente()"
            >
              Salvar
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              block
              @click="LimparCampos()"
            >
              Limpar Campos
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexContrato',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      url: 'admin/obra',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      nome: null,
      disciplina: null,
      opcoesdisciplina: [],
      atividade: null,
      opcoesatividade: [],
    }
  },

  async created() {
    await this.passaObra(this.url)
    await this.$http.get('admin/disciplina_categoria').then(disciplina => {
      const diaciplinaBanco = disciplina.data.data
      const disciplinasR = []
      diaciplinaBanco.forEach(dis => {
        disciplinasR.push({
          value: dis.id,
          name: dis.nome,
        })
      })
      this.opcoesdisciplina = disciplinasR
    })
    await this.$http.get('admin/atividade_categoria').then(atividade => {
      const atividadeBanco = atividade.data.data
      const atividadesR = []
      atividadeBanco.forEach(atv => {
        atividadesR.push({
          value: atv.id,
          name: atv.nome,
        })
      })
      this.opcoesatividade = atividadesR
    })
  },
  methods: {
    async passaObra(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    LimparCampos() {
      this.id = null
      this.nome = null
      this.descricao = null
    },
    SalvarCliente() {
      const obj = {
        nome: this.nome,
        descricao: this.descricao,
      }
      if (this.id === null) {
        this.$http.post('admin/disciplina_categoria', obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
          }
          this.reloadTable()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
